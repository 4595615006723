<template>
  <div>
    <div>

    <div class="title111">
    <van-sticky offset-top="-0.9rem">
    <van-cell :title="'选择月份：' + nowMonthTitle" @click="monthSelectShow = true" class="monthSel" />
    <van-action-sheet v-model="monthSelectShow">
        <div class="monthSelectContent">
            <van-datetime-picker
            v-model="monthDatetime"
            type="year-month"
            title="选择月份"
            :max-date="maxDate"
            :formatter="formatter"
            @confirm="monthChange"
            @cancel="monthSelectShow = false"
            />
        </div>
    </van-action-sheet>
    
    <van-tabs background="#20a0ff" title-active-color="#fff" title-inactive-color="#3c3c45" @change="dateChange" v-model="nowDay" style="height:1.17333rem">
        <van-tab v-for="item in dateArray" :key="item.day" :name="item.day">
            <template #title>
                <div style="text-align:center;width:0.6rem;padding-bottom:10px">
                    <span style="font-size:90%;">{{weekString[item.weekday]}}</span><br>
                    <span>{{item.day}}</span>
                </div>
            </template>
        </van-tab>
    </van-tabs>
    </van-sticky>
    </div>


    <div style="width:100%;height:250px;background-color:#fff"><div id="main" style="width:250px;height:250px;margin:0 auto"></div></div>
    <van-sticky offset-top="1.35333rem">
        <div style="background:#fff;">
    <van-tabs v-model="stateActive" :fixed="false" @change="stateChange" class="stateActiveClass" color="#20a0ff">
        <van-tab name="submit">
            <template #title>
                <div style="text-align:center">
                    <span style="color:#409eff;font-size:0.58rem">{{submitList.length}}</span>
                    <span style="font-size:0.32rem;color:#333">人</span>
                </div>
                <div style="font-size:0.32rem;color:#333;text-align:center">已提交</div>
            </template>
        </van-tab>
        <van-tab name="unsubmit">
            <template #title>
                <div style="text-align:center">
                    <span style="color:#ffa366;font-size:0.58rem">{{unSubmitList.length}}</span>
                    <span style="font-size:0.32rem;color:#333">人</span>
                </div>
                <div style="font-size:0.32rem;color:#333;text-align:center">待提交</div>
            </template>
        </van-tab>
        <van-tab name="unfill">
            <template #title>
                <div style="text-align:center">
                    <span style="color:#ff4500;font-size:0.58rem">{{unFillList.length + unFillLeaveList.length}}</span>
                    <span style="font-size:0.32rem;color:#333">人</span>
                </div>
                <div style="font-size:0.32rem;color:#333;text-align:center">未填写</div>
            </template>
        </van-tab>
    </van-tabs>
    <van-cell v-if="stateActive == 'unfill' && (user.timeType.syncCorpwxTime == 1 || user.timeType.syncDingding == 1 || user.company.packageOa == 1)">
        <template #default>
            <van-tabs type="card" @change="unfillSelect" v-model="unfillSelKey" color="#20a0ff">
                <van-tab title="当天未填" :name="0"></van-tab>
                <van-tab title="全天请假" :name="1"></van-tab>
            </van-tabs>
        </template>
    </van-cell>
        </div>
    </van-sticky>
    </div>
    <van-cell-group style="margin-bottom:1.33333rem">
        <van-cell v-if="listLoading">
            <template #default>
                <van-loading color="#1989fa" size="24" text-size="12" style="text-align:center;">加载中...</van-loading>
            </template>
        </van-cell>
        <van-cell v-if="showList.length == 0">
            <template #default>
                <div style="text-align:center;color:#7d7e80;font-size:13px">暂无数据</div>
            </template>
        </van-cell>
        <template v-else>
            <van-cell v-for="item in showList" :key="item.id" title-style="color:#666">
                <template #title>
                    <div style="text-align:left;margin-left:0.42667rem;" v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='item.name'></TranslationOpenDataText></div>
                    <div style="text-align:left;margin-left:0.42667rem;" v-else>{{item.name}}</div>
                </template>
                <template #default>
                    <div style="text-align:right;margin-right:0.42667rem;" v-if="user.userNameNeedTranslate == '1'"><TranslationOpenDataText type='departmentName' :openid='item.department'></TranslationOpenDataText></div>
                    <div style="text-align:right;margin-right:0.42667rem;" v-else>{{item.department}}</div>
                </template>
                <template #label>
                    <div class="cellLabel">
                        <div>
                            填报工时： {{ item.workingTime }} h
                            <span v-if="item.state == 1" style="color: #409EFF">已通过</span>
                            <span v-if="item.state == 3" style="color: #FFA366">待提交</span>
                            <span v-if="item.state == 0" style="color: #FFA366">待审核</span>
                        </div>
                    </div>
                </template>
            </van-cell>
        </template>
    </van-cell-group>
    <Footer page="count"></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Footer";
export default {
    components: {
        Footer
    },
    data() {
        return{
            user: JSON.parse(localStorage.userInfo),
            reportsCompany: false,
            reportsDept: false,

            monthSelectShow: false,
            monthDatetime: new Date(),
            maxDate: new Date(),
            nowMonthTitle: '',
            dateArray:[],
            weekString:['日','一','二','三','四','五','六'],
            nowDay: new Date().getDate(),
            nowLookDate: '',    //当前选中的日期
            unfillSelKey: 0,

            submitList:[],  //已提交人员列表
            unSubmitList:[],    //未提交人员列表
            unFillList:[],  //未填写人员列表
            unFillLeaveList:[],
            showList:[],
            listLoading: false,

            stateActive: 'submit'
        }
    },
    methods: {
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            }
            return val;
        },

        monthChange(value){
            this.nowMonthTitle = value.getFullYear() + '年' + (value.getMonth() + 1) + '月'
            this.monthSelectShow = false
            let daylength = new Date(value.getFullYear(),(value.getMonth() + 1),0).getDate()
            this.dateArray = []
            for(let i = 1; i < daylength + 1; i++){
                let item = {
                    day: i,
                    weekday: new Date(value.getFullYear(),value.getMonth(),i).getDay()
                }
                this.dateArray.push(item)
            }
            let newdate = new Date()
            if(value.getFullYear() == newdate.getFullYear() && value.getMonth() == newdate.getMonth()){
                this.dateChange(newdate.getDate())
            }else{
                this.dateChange(1)
            }
            // console.log('当前月份',this.dateArray);
        },

        dateChange(name){
            this.nowDay = name
            let nyear = this.monthDatetime.getFullYear()
            let nmonth = this.monthDatetime.getMonth() + 1
            this.nowLookDate = nyear + '-' + (nmonth < 10 ? '0' + nmonth : nmonth) + '-' + (name < 10 ? '0' + name : name) 
            // console.log('日期切换',this.nowLookDate,this.nowDay)
            this.getCountData()
        },
        stateChange(active){
            this.stateActive = active
            if(active == 'submit'){
                this.showList = this.submitList
            }else if(active == 'unsubmit'){
                this.showList = this.unSubmitList
            }else if(active == 'unfill'){
                this.unfillSelect(0)
            }
        },

        unfillSelect(name){
            this.unfillSelKey = name
            if(name == 0){
                this.showList = this.unFillList
            }else{
                this.showList = this.unFillLeaveList
            }
        },
        

        getCountData(){ //获取填报统计数据
            let parameter = {
                date: this.nowLookDate
            }
            if (this.user.manageDeptId != 0 && !this.reportsCompany && !this.reportsDept) {
                parameter.manageDeptId = this.user.manageDeptId; 
            }
            this.listLoading = true
            
            this.$axios.post('/report/getMembList', parameter)
            .then(res => {
                // console.log('resok',parameter.date == this.nowLookDate);
                if(res.code == 'ok'){
                    this.submitList = []
            this.unSubmitList = []
            this.unFillList = []
            this.unFillLeaveList = []
                    this.extractUser(res.data)
                    this.drawChart()
                    this.listLoading = false
                    this.stateChange('submit')
                }else {
                    this.listLoading = false
                    this.$toast.clear();
                    this.$toast.fail('获取失败:'+res.msg);
                } 
            }).catch(err=> {
                this.listLoading = false
                this.$toast.clear();
            });
        },
        extractUser(list){
            for(let i in list){
                if(list[i].children){
                    this.extractUser(list[i].children)
                }
                if(list[i].userList){
                    let deptname = list[i].label
                    list[i].userList.forEach(element => {
                        element.department = deptname
                        if(element.workingTime){
                            if(element.state == 3){ //待提交
                                this.unSubmitList.push(element)
                            }else{  //已提交
                                this.submitList.push(element)
                            }
                        }else{  //未填写
                            if((element.leaveDays >= 1 || element.leaveTimes >= this.user.timeType.allday) && (this.user.timeType.syncCorpwxTime == 1 || this.user.timeType.syncDingding == 1 || this.user.company.packageOa == 1)){
                                this.unFillLeaveList.push(element)  //全天请假
                            }else{
                                this.unFillList.push(element)   //非全天请假
                            }
                        }
                    });
                }
            }
        },

        drawChart(){
            this.$echarts.init(document.getElementById('main')).dispose()
            var myChart = this.$echarts.init(document.getElementById('main'));
            // 绘制图表
            myChart.setOption({
                series: [
                    {
                    type: 'pie',
                    color:['#5d9cec','#fc8d52','#ed5555'],
                    label: {
                        show: false
                    },
                    data: [this.submitList.length, this.unSubmitList.length, this.unFillList.length + this.unFillLeaveList.length]
                    // data: [30,20,10]
                    }
                ]
            });
            myChart.on('click',(params)=>{
                if(params.dataIndex == 0){
                    this.stateChange('submit')
                }else if(params.dataIndex == 1){
                    this.stateChange('unsubmit')
                }else{
                    this.stateChange('unfill')
                }
            })
        }
    },
    mounted() {
        
        this.monthChange(new Date())
        for(let i in this.user.functionList){
            if(this.user.functionList[i].name == '查看全公司工时'){
                this.reportsCompany = true
            }
            if(this.user.functionList[i].name == '查看本部门工时'){
                this.reportsDept = true
            }
        }
        // console.log('请假填报',this.leaveFil);
    }
}
</script>

<style>
body{
    /* background: #fff; */
}
.monthSelectContent {
    padding: 16px 16px 16px;
}
.monthSel {
    background-color: #20a0ff;
    color: #fff;
    font-size: 12px !important;
    padding-top: 0.14rem;
    padding-left: 0.43rem;
    padding-bottom: 0.3rem;
    height: 1.08rem;
}
.monthSel::after{
    display: none;
}
.stateActiveClass{
    padding: 0.15rem 0;
}
.stateActiveClass .van-tabbar-item{
    font-size: 0.3rem;
}
.stateActiveClass .van-tabbar-item .van-tabbar-item__text{
    padding-top: 0.1rem;
}
.stateActiveClass .van-tab__text--ellipsis{
    -webkit-line-clamp: initial;
    padding-bottom: 0.1rem;
}
.title111 {
    background-color: #20a0ff;
    /* border-radius: 5px; */
    overflow: hidden;
}
.title111 .van-tabs__line{
    background-color: #f7f7f7;
}
.cellLabel {
    padding: 0 15px;
    box-sizing: border-box;
}
.cellLabel span {
    padding-left: 10px;
}
</style>